import camelCase from 'lodash.camelcase';

import { camelConvert, kebabConvert } from 'utils/caseConversion';

import { JSONApiErrorError } from './errors';

const API_BASE = process.env.NEXT_PUBLIC_API_BASE;

const defaultRequestHeaders = {
  Accept: 'application/vnd.api+json',
  'Content-Type': 'application/vnd.api+json',
};

function serialize(
  req: Partial<DyrtModel>,
  relationships?: Record<string, JSONAPIRelationship>
): JSONAPIDocument {
  const { id, type, ...attributes } = kebabConvert(req);
  return {
    data: {
      id: req.id,
      type: req.type as DyrtModel['type'],
      attributes,
      relationships: kebabConvert(relationships || {}) as Record<
        string,
        JSONAPIRelationship
      >,
    },
  };
}

function deserialize<T extends DyrtModel, I extends DyrtModel = DyrtModel>(
  data: JSONAPIResource,
  included?: JSONAPIResource[],
  meta?: Record<string, unknown>,
  withRelated?: boolean
): DeserializedJSONAPIResult<T, I>;
function deserialize<T extends DyrtModel, I extends DyrtModel = DyrtModel>(
  data: JSONAPIResource[],
  included?: JSONAPIResource[],
  meta?: Record<string, unknown>,
  withRelated?: boolean
): DeserializedJSONAPIResults<T, I>;
function deserialize<T extends DyrtModel, I extends DyrtModel = DyrtModel>(
  data: JSONAPIResource[] | JSONAPIResource,
  included?: JSONAPIResource[],
  meta?: Record<string, unknown>,
  withRelated?: boolean
): DeserializedJSONAPIResults<T, I> | DeserializedJSONAPIResult<T, I> {
  let deserializedData,
    deserializedIncludes: I[] = [],
    deserializedMeta;

  const deserializeRecord = <P extends DyrtModel = DyrtModel>(
    resource: JSONAPIResource,
    includes: I[] = []
  ): P => {
    if (typeof resource.id === 'undefined') {
      throw new Error(`Tried to deserialize resource with an undefined ID`);
    }
    const camelAttributes = camelConvert(resource.attributes);

    const record = {
      id: resource.id,
      type: resource.type as P['type'],
      ...camelAttributes,
    } as P;

    if (!withRelated || !resource.relationships) {
      return record;
    }

    function findRelated(relationshipData: RelationshipData): I | undefined {
      return includes.find(
        ({ id, type }) =>
          id === relationshipData.id && type === relationshipData.type
      );
    }

    const { relationships } = resource;
    const related: RelatedRecords = {};
    const typeNames: string[] = Object.keys(relationships);

    typeNames.forEach((typeName) => {
      const { data: relationshipData } = relationships[typeName];
      const modelName = camelCase(typeName);

      if (Array.isArray(relationshipData)) {
        const relatedRecords: I[] = [];
        relationshipData.forEach((rd) => {
          const includedRecord = findRelated(rd);
          includedRecord && relatedRecords.push(includedRecord);
        });
        related[modelName] = relatedRecords;
      } else if (relationshipData) {
        const includedRecord = findRelated(relationshipData);
        related[modelName] = includedRecord || null;
      }
    });

    return { ...record, related };
  };

  if (Array.isArray(included)) {
    deserializedIncludes = included.map((d) =>
      deserializeRecord<I>(d as JSONAPIResource)
    );
  }

  if (Array.isArray(data)) {
    deserializedData = data.map((d) =>
      deserializeRecord<T>(d as JSONAPIResource, deserializedIncludes)
    );
  } else {
    deserializedData = deserializeRecord<T>(
      data as JSONAPIResource,
      deserializedIncludes
    );
  }

  if (meta) {
    deserializedMeta = camelConvert<Meta>(meta);
    if (meta['available-sort-options']) {
      deserializedMeta.availableSortOptions = meta['available-sort-options'];
    }
  }

  if (Array.isArray(deserializedData)) {
    return {
      data: deserializedData,
      included: deserializedIncludes as I[],
      meta: deserializedMeta,
    };
  }

  return {
    data: deserializedData,
    included: deserializedIncludes as I[],
    meta: deserializedMeta,
  };
}

function getOptionsQueryString(options: JSONAPIOptions): string {
  const params = new URLSearchParams();
  Object.entries(options).forEach(([key, value]) => {
    if (typeof value === 'object' && value !== null) {
      Object.entries(value).forEach(([subkey, subvalue]) => {
        if (typeof subvalue === 'object' && subvalue !== null) {
          Object.entries(subvalue).forEach(([nestedkey, nestedvalue]) => {
            params.append(`${key}[${subkey}][${nestedkey}]`, nestedvalue + '');
          });
        } else params.append(`${key}[${subkey}]`, subvalue + '');
      });
    } else {
      params.append(key, value);
    }
  });
  return params.toString();
}

export const loadRecord = async <
  T extends DyrtModel,
  I extends DyrtModel = DyrtModel
>(
  typeOrEndpoint: string,
  id: string,
  options: JSONAPIOptions = {},
  headers?: Record<string, string>,
  signal?: AbortSignal,
  withRelated?: boolean
): Promise<DeserializedJSONAPIResult<T, I>> => {
  const apiOptions = getOptionsQueryString(options);

  const apiURL = new URL(`${API_BASE}/${typeOrEndpoint}/${id}?${apiOptions}`);

  const response = await fetch(apiURL.toString(), { headers, signal });

  const apiResponse = await response.text();
  let jsonDocument: JSONAPIDocument;

  try {
    jsonDocument = JSON.parse(apiResponse) as JSONAPIDocument;
  } catch (error) {
    throw new JSONApiErrorError(
      [
        {
          status: String(response.status),
          title: response.ok
            ? `Error loading ${typeOrEndpoint}`
            : String(response.status),
          detail: `Error loading ${typeOrEndpoint}`,
          source: {
            pointer: apiURL.toString(),
          },
        },
      ],
      response.status,
      response.url
    );
  }

  if (jsonDocument.errors) {
    throw new JSONApiErrorError(
      jsonDocument.errors,
      response.status,
      response.url
    );
  }

  if (!jsonDocument.data) {
    throw new Error(`API response contains no data for ${apiURL.toString()}.`);
  }

  if (Array.isArray(jsonDocument.data)) {
    throw new Error(`Unexpected API data for endpoint ${apiURL.toString()}`);
  }

  return deserialize<T, I>(
    jsonDocument.data,
    jsonDocument.included,
    jsonDocument.meta,
    withRelated
  );
};

export const loadRecords = async <
  T extends DyrtModel,
  I extends DyrtModel = DyrtModel
>(
  typeOrEndpoint: string,
  options: JSONAPIOptions = {},
  headers?: Record<string, string>,
  signal?: AbortSignal,
  apiBase?: string,
  withRelated?: boolean
): Promise<DeserializedJSONAPIResults<T, I>> => {
  const apiOptions = getOptionsQueryString(options);
  const apiURL = new URL(
    `${apiBase || API_BASE}/${typeOrEndpoint}?${apiOptions}`
  );

  const response = await fetch(apiURL.toString(), { headers, signal });

  const apiResponse = await response.text();
  let jsonDocument = {} as JSONAPIDocument;
  try {
    jsonDocument = JSON.parse(apiResponse);
  } catch (error) {
    throw new JSONApiErrorError(
      [
        {
          status: String(response.status),
          title: response.ok
            ? `Error loading ${typeOrEndpoint}`
            : String(response.status),
          detail: `Error loading ${typeOrEndpoint}`,
          source: {
            pointer: apiURL.toString(),
          },
        },
      ],
      response.status,
      response.url
    );
  }

  if (jsonDocument.errors) {
    throw new JSONApiErrorError(
      jsonDocument.errors,
      response.status,
      response.url
    );
  }

  if (!jsonDocument.data) {
    throw new Error(`API response contains no data for ${apiURL.toString()}.`);
  }

  if (!Array.isArray(jsonDocument.data)) {
    throw new Error(`Unexpected API data for endpoint ${apiURL.toString()}`);
  }

  return deserialize<T, I>(
    jsonDocument.data,
    jsonDocument.included,
    jsonDocument.meta,
    withRelated
  );
};

export const createRecord = async <
  T extends DyrtModel,
  I extends DyrtModel = DyrtModel
>(
  record: Partial<T>,
  options: JSONAPIOptions = {},
  headers?: Record<string, string>,
  relationships?: Record<string, JSONAPIRelationship>
): Promise<DeserializedJSONAPIResult<T, I>> => {
  const payload = serialize(record, relationships);
  const apiURL = new URL(
    `${API_BASE}/${record.type}?${getOptionsQueryString(options)}`
  );
  if (Array.isArray(payload.data)) {
    throw new Error('Unexpected array in payload');
  }
  const response = await fetch(apiURL.toString(), {
    method: 'POST',
    headers: {
      ...defaultRequestHeaders,
      ...headers,
    },
    body: JSON.stringify(payload),
    mode: 'cors',
  });

  const apiResponse = await response.text();

  let serialResponse = {} as JSONAPIDocument;
  try {
    serialResponse = JSON.parse(apiResponse);
  } catch (parseError) {
    if (!response.ok) {
      throw new JSONApiErrorError(
        [
          {
            status: String(response.status),
            title: `Error creating ${record.type}`,
            detail: `Error creating ${record.type}`,
            source: {
              pointer: apiURL.toString(),
            },
          },
        ],
        response.status,
        response.url
      );
    }
  }
  if (serialResponse.errors) {
    throw new JSONApiErrorError(
      serialResponse.errors,
      response.status,
      response.url
    );
  }
  return deserialize<T, I>(
    serialResponse.data as JSONAPIResource,
    serialResponse.included,
    serialResponse.meta
  );
};

export const updateRecord = async <
  T extends DyrtModel,
  I extends DyrtModel = DyrtModel
>(
  record: Partial<T>,
  options: JSONAPIOptions = {},
  headers?: Record<string, string>,
  relationships?: Record<string, JSONAPIRelationship>
): Promise<DeserializedJSONAPIResult<T>> => {
  const payload = serialize(record, relationships);
  const apiURL = new URL(
    `${API_BASE}/${record.type}/${record.id}?${getOptionsQueryString(options)}`
  );
  if (Array.isArray(payload.data)) {
    throw new Error('Unexpected array in payload');
  }
  const response = await fetch(apiURL.toString(), {
    method: 'PATCH',
    headers: {
      ...defaultRequestHeaders,
      ...headers,
    },
    body: JSON.stringify(payload),
    mode: 'cors',
  });

  const apiResponse = await response.text();
  let serialResponse = {} as JSONAPIDocument;
  try {
    serialResponse = JSON.parse(apiResponse);
  } catch (parseError) {
    if (!response.ok) {
      throw new JSONApiErrorError(
        [
          {
            status: String(response.status),
            title: `Error updating ${record.type}`,
            detail: `Error updating ${record.type}`,
            source: {
              pointer: apiURL.toString(),
            },
          },
        ],
        response.status,
        response.url
      );
    }
  }
  if (serialResponse.errors) {
    throw new JSONApiErrorError(
      serialResponse.errors,
      response.status,
      response.url
    );
  }
  return deserialize<T, I>(
    serialResponse.data as JSONAPIResource,
    serialResponse.included,
    serialResponse.meta
  );
};

export const deleteRecord = async <T extends DyrtModel>(
  record: Partial<T>,
  options: JSONAPIOptions = {},
  headers?: Record<string, string>
): Promise<void> => {
  const apiURL = new URL(
    `${API_BASE}/${record.type}/${record.id}${getOptionsQueryString(options)}`
  );
  const response = await fetch(apiURL.toString(), {
    method: 'DELETE',
    headers: {
      ...defaultRequestHeaders,
      ...headers,
    },
  });

  if (response.status === 204) {
    // all good
  } else {
    throw new Error(
      `Error deleting ${record.id} from ${record.type}. API returned a ${response.status}`
    );
  }
};
